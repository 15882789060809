import { computed, ref } from 'vue'

const pendingRequests = ref(new Map())

export function setApiRequestPending(uri: string) {
  pendingRequests.value.set(uri, true)
}

export function setApiRequestComplete(uri: string) {
  pendingRequests.value.delete(uri)
}

export function useApiRequests(uri?: string) {
  const isPending = computed(() => {
    let status
    for (const [k, v] of pendingRequests.value) {
      if (uri) {
        if (k === uri) {
          status = v
          break;
        } else if (uri.substring(uri.length - 1) == "$" && k.endsWith(uri.substring(0, uri.length - 1))) {
          status = v
        } else if (k.includes(uri)) {
          status = v
        }
      } else {
        status = v
      }
    }

    return status
  })

  return {
    isPending,
  }
}
