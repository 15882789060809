import { defineStore, storeToRefs } from 'pinia'
import { useApiErrors } from '@/store/api-errors'
import { useApiRequests } from '@/store/api-requests'
import client, { responseBody } from '@/api/client'

type StoreType = {
    latitude: number | null;
    longitude: number | null;
}

export const useUserStore = defineStore('userPosition', {
    state: (): StoreType => ({
        latitude: null,
        longitude: null,
    }),
    actions: {
        async setPosition(latitude: number, longitude: number) {
            this.latitude = latitude
            this.longitude = longitude
        },
        savePosition(latitude: number, longitude: number) {
            return client
                .post("/v2/user/save-position", { latitude, longitude })
                .then<Record<string, never>>(responseBody)
        },
    },
})


export function useUserPosition() {
    const store = useUserStore()

    const { error } = useApiErrors('/save-position')
    const { isPending } = useApiRequests('/save-position')

    const { latitude, longitude } = storeToRefs(store)

    return {
        latitude,
        longitude,
        setPosition: store.setPosition,
        savePosition: store.savePosition,
        isPending,
        error,
    }
}

