import qs from "qs";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  AuthHeaderRequestInterceptor,
  StoreClearApiErrorInterceptor,
  StoreSaveApiErrorInterceptor,
  StoreSetRequestPendingInterceptor,
  StoreSetRequestCompleteResponseInterceptor,
  StoreSetRequestCompleteErrorInterceptor,
} from '@/api/interceptors'
import {
  FormErrorsType,
  FormError,
  FormErrors,
  ServerError,
  UnknownError,
} from "@/models/error";
import { useUser } from '@/store/user'

const opts: AxiosRequestConfig = {
  paramsSerializer: qs.stringify,
  baseURL: process.env.VUE_APP_API_BASE_URL,
};

const client = axios.create(opts);

// Request interceptors, order matters!
client.interceptors.request.use(StoreSetRequestPendingInterceptor);
client.interceptors.response.use(StoreSetRequestCompleteResponseInterceptor, StoreSetRequestCompleteErrorInterceptor);

client.interceptors.response.use(StoreClearApiErrorInterceptor, StoreSaveApiErrorInterceptor);


client.interceptors.response.use(
  (response) => response,
  (error): Promise<FormError | FormErrors | ServerError | UnknownError> => {
    if (error.response) {
      const { status, data } = error.response;
      if (status == 400 || status == 401) {
        if (data.type == "form") {
          if (data.errors) {
            const globalErrors: FormError[] = [];
            const allErrors = new FormErrors();
            (data.errors as FormErrorsType[]).forEach((v) => {
              const origin = v.origin;
              if (!origin) {
                globalErrors.push(new FormError(v.message));
              } else {
                allErrors[origin] = new FormError(v.message);
              }
            });

            allErrors.global = globalErrors;

            return Promise.reject(allErrors);
          } else if (data.error) {
            return Promise.reject(new FormError(error.message));
          }
        } else if (typeof data.message == "string") {
          return Promise.reject(new UnknownError(data.message));
        }
      }

      if (typeof data.detail == "string") {
        return Promise.reject(new ServerError(data.detail));
      }
    }

    return Promise.reject(error);
  }
);

client.interceptors.request.use(
  AuthHeaderRequestInterceptor,
  (error) => Promise.reject(error)
);


export const authHeaders = (
  config?: AxiosRequestConfig
): AxiosRequestConfig => {
  if (!config) {
    config = {};
  }

  const { authToken } = useUser()
  if (authToken.value) {
    if (!config.headers) {
      config.headers = {};
    }

    config.headers["Authorization"] = `Bearer ${authToken.value}`;
  }

  return config;
};

export const responseBody = (response: AxiosResponse) => response.data;
export type EmptyJsonResponse = Record<string, never>;

export default client;
