import { defineStore, storeToRefs } from 'pinia'
import type { UserDetails } from '@/models/user'
import type { LoginFormType, ResetPasswordFormType } from '@/models/login'
import client, { responseBody } from '@/api/client'
import { useApiErrors } from '@/store/api-errors'
import { useApiRequests } from '@/store/api-requests'
import { useLocalStorage } from '@vueuse/core'

type StoreType = {
    user: UserDetails | null;
    authToken: unknown;
}

export const useUserStore = defineStore('user', {
    state: (): StoreType => ({
        user: null,
        authToken: useLocalStorage('authToken', null)
    }),
    getters: {
        hasAuthToken: (state) => state.authToken !== null,
        isDriver: (state) => state.user?.isDriver,
        isCustomer: (state) => state.user?.isCustomer,
        isAdmin: (state) => state.user?.isAdmin,
    },
    actions: {
        setAuthToken(payload: string) {
            this.authToken = payload
        },
        async getUser() {
            this.user = await client
                .get("/v2/user/details")
                .then(responseBody)
        },
        async logoutUser() {
            this.authToken = null
            this.user = null
        },
        async loginUser(form: LoginFormType) {
            const { token } = await client
                .post('/login', form, { headers: { Authorization: '' } })
                .then(responseBody)

            this.setAuthToken(token)
        },
        forgotPassword(email: string) {
            return client
                .post("/v2/forgot-password", { email }, { headers: { Authorization: '' } })
                .then<Record<string, never>>(responseBody)
        },
        verifyResetPassword(code: string) {
            return client
                .post("/v2/verify-password-reset-code", { code }, { headers: { Authorization: '' } })
                .then<Record<string, never>>(responseBody)
        },
        resetPassword(code: string, password: ResetPasswordFormType) {
            return client
                .post("/v2/reset-password", { code, form: { password } }, { headers: { Authorization: '' } })
                .then<Record<string, never>>(responseBody)
        },
    },
})


export function useUser() {
    const store = useUserStore()
    const { error } = useApiErrors('/login')
    const { isPending } = useApiRequests('/login')

    const { user, isDriver, isCustomer, isAdmin, authToken, hasAuthToken } = storeToRefs(store)

    return {
        user,
        isDriver,
        isCustomer,
        isAdmin,
        authToken,
        isPending,
        getUser: store.getUser,
        loginUser: store.loginUser,
        logoutUser: store.logoutUser,
        hasAuthToken,
        error,
    }
}


export function useForgotPassword() {
    const store = useUserStore()
    const { error } = useApiErrors('/forgot-password')
    const { isPending } = useApiRequests('/forgot-password')

    return {
        forgotPassword: store.forgotPassword,
        error,
        isPending,
    }
}

export function useVerifyResetPassword() {
    const store = useUserStore()
    const { error } = useApiErrors('/verify-password-reset-code')

    return {
        verifyResetPassword: store.verifyResetPassword,
        error,
    }
}

export function useResetPassword() {
    const store = useUserStore()
    const { error, errors } = useApiErrors('/reset-password')
    const { isPending } = useApiRequests('/reset-password')

    return {
        resetPassword: store.resetPassword,
        error,
        errors,
        isPending,
    }
}