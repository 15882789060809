import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue"
import { useUser } from '@/store/user'
import { useErrorsStore } from '@/store/api-errors'


const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgot_password",
    component: () => import("../views/ForgotPassword.vue"),
  },
  {
    path: "/reset-password-code",
    name: "reset_password_code",
    component: () => import("../views/ResetPasswordCode.vue"),
  },
  {
    path: "/reset-password/:code",
    name: "reset_password",
    component: () => import("../views/ResetPassword.vue"),
  },
  {
    path: "/book",
    name: "book",
    component: () => import("../views/Book.vue"),
  },
  {
    path: "/book/select-car",
    name: "book_select_car",
    component: () => import("../views/BookSelectCar.vue"),
  },
  {
    path: "/book/summary",
    name: "book_summary",
    component: () => import("../views/BookSummary.vue"),
  },
  {
    path: "/book/payment/:id/:hash",
    name: "book_payment",
    component: () => import("../views/BookPayment.vue"),
  },
  {
    path: "/payment-successful/:id/:hash",
    name: "book_payment_successful",
    component: () => import("../views/BookPaymentSuccessful.vue"),
  },
  {
    path: "/payment-failed/:id/:hash",
    name: "book_payment_failed",
    component: () => import("../views/BookPaymentFailed.vue"),
  },
  {
    path: "/bookings",
    name: "bookings",
    component: () => import("../views/Bookings.vue"),
    meta: { requireAuth: true, }
  },
  {
    path: "/bookings/details/:id(\\d+)",
    name: "booking_details",
    component: () => import("../views/BookingsDetail.vue"),
    meta: { requireAuth: true, }
  },
  {
    path: "/bookings/vehicle-location/:id(\\d+)",
    name: "booking_vehicle_location",
    component: () => import("../views/BookingsVehicleLocation.vue"),
    meta: { requireAuth: true, }
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


// Get user details on each page
router.beforeEach(async (to, from, next) => {
  const { hasAuthToken, getUser, user, logoutUser } = useUser()

  if (hasAuthToken.value && !user.value) {
    try {
      await getUser()
    } catch (e) {
      logoutUser()
    }
  }

  if (to.meta.requireAuth && !user.value) {
    next({ name: 'login', query: { returnUrl: to.fullPath } })
  } else {
    next()
  }
})


// Clear store errors after leaving each page
router.afterEach(() => {
  const store = useErrorsStore()
  store.$reset()
})

export default router;
