<template>
  <router-view />
</template>


<style lang="scss">
#app {
  height: 100%;
}

@media (min-width: 478px) {
  #app {
    width: 375px;
    margin: 0 auto;
    height: 100%;
  }
}
</style>

