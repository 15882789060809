import { createApp } from "vue";
import { createPinia } from 'pinia'
import App from "./App.vue";
import router from "./router";

import FormControl from "./components/FormControl.vue";
import Error from "./components/Error.vue";
import AppHeader from "./components/AppHeader.vue";
import AppHeaderProgressBar from "./components/AppHeaderProgressBar.vue";
import UserPositionBar from "./components/UserPositionBar.vue";
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import "@/assets/css/styles.scss";

const pinia = createPinia()
const app = createApp(App);

app.component("AppHeader", AppHeader);
app.component("AppHeaderProgressBar", AppHeaderProgressBar);
app.component("UserPositionBar", UserPositionBar);
app.component("Error", Error);
app.component("FormControl", FormControl);

app.use(Toast, {
    position: 'top-center',
    transition: 'Vue-Toastification__fade',
    hideProgressBar: true,
})
app.use(pinia)
app.use(router);
app.mount("#app");
