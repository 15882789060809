import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import { setApiError, deleteApiError } from '@/store/api-errors'
import { setApiRequestPending, setApiRequestComplete } from '@/store/api-requests'
import { useUser } from '@/store/user'

/**
 * Adds Bearer request header to all requests
 */
export const AuthHeaderRequestInterceptor = (config: AxiosRequestConfig) => {
  const { hasAuthToken, authToken } = useUser()
  
  if (hasAuthToken.value) {
    if (!config.headers || config.headers.Authorization === undefined) {
      const newHeaders = !config.headers ? {} : config.headers;
      newHeaders.Authorization = `Bearer ${authToken.value}`

      config.headers = newHeaders;
    } else {
      // If auth header passed empty explicitly remove it.
      if (config.headers.Authorization === "") {
        delete config.headers.Authorization
      }
    }
  }

  return config
}

/**
 * Add/Remove errors from store.
*/
const isErrorResponse = (r: AxiosResponse) => r.status == 401 || r.status == 400
export const StoreClearApiErrorInterceptor = (response: any) => {
  if (response.config.url) {
    deleteApiError(response.config.url);
  }

  return response
}

export const StoreSaveApiErrorInterceptor = (err: any) => {
  if (isErrorResponse(err.response)) {
    if (err.config.url && err.response.data) {
      deleteApiError(err.config.url);

      if (err.response.data.message) {
        setApiError(err.config.url, { detail: err.response.data.message })
      } else if (err.response.data.detail) {
        setApiError(err.config.url, { detail: err.response.data.detail })
      } else if (err.response.data.errors?.length > 0) {
        const violations = err.response.data.errors.map((v: { message: string, origin: string }) => {
          return {
            detail: v.message,
            propertyPath: v.origin,
          }
        })

        setApiError(err.config.url, { violations })
      }
    }
  }

  return Promise.reject(err)
}








/**
 * Set/Unset pending request flag.
 */
export const StoreSetRequestPendingInterceptor = (config: AxiosRequestConfig) => {
  if (config.url) {
    setApiRequestPending(config.url)
  }

  return config
}

export const StoreSetRequestCompleteResponseInterceptor = (response: AxiosResponse) => {
  if (response.config.url) {
    setApiRequestComplete(response.config.url)
  }

  return response
}

export const StoreSetRequestCompleteErrorInterceptor = (err: any) => {
  if (err.config.url) {
    setApiRequestComplete(err.config.url)
  }

  return Promise.reject(err)
}