import { computed, nextTick } from 'vue'
import { defineStore } from 'pinia'
import type { ApiErrorResponse } from '@/models/error'

type StateType = { errors: Map<string, ApiErrorResponse> }

export const useErrorsStore = defineStore("apiErrors", {
  state: (): StateType => ({
    errors: new Map(),
  }),
})

export const deleteApiError = (uri: string) => {
  const store = useErrorsStore()
  store.errors.delete(uri)
}

export const setApiError = async (uri: string, apiError: ApiErrorResponse) => {
  const store = useErrorsStore()
  await nextTick()
  store.errors.set(uri, apiError)
}

const getApiError = (uri?: string): ApiErrorResponse | undefined => {
  const store = useErrorsStore()

  let error
  for (const [k, v] of store.errors) {
    if (uri) {
      if (k === uri) {
        error = v
        break;
      } else if (uri.substring(uri.length - 1) == "$" && k.endsWith(uri.substring(0, uri.length - 1))) {
        error = v
      } else if (k.includes(uri)) {
        error = v
      }
    } else {
      error = v
    }
  }

  return error
}

export function useApiErrors(uri?: string) {
  const error = computed<string | undefined>(() => {
    const error = getApiError(uri)
    return error?.detail
  })  

  const errors = computed(() => {
    const errors: { [propertyPath: string]: string } = {}

    const error = getApiError(uri)
    if (error?.violations) {
      for (const v of error.violations) {
        if (v.propertyPath) {
          errors[v.propertyPath] = v.detail
        }
      }
    }

    return errors
  })

  return {
    error,
    errors,
  }
}