export type ApiErrorResponse = {
  detail?: string;
  violations?: {
    detail: string;
    propertyPath: string;
  }[];
}

export interface FormErrorsType {
  message: string;
  origin: string;
}

export class FormError {
  constructor(public message: string) {}
}

export class FormErrors {
  [origin: string]: FormError | FormError[];
}

export class ServerError {
  constructor(public detail: string) {}

  get message(): string {
    return this.detail;
  }
}

export class UnknownError {
  constructor(public message: string) {}
}
